import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import store from '@/store'
import router from '@/router'

export default function mystoreViewModel() {
  // Use toast
  const toast = useToast()

  const refStoreListTable = ref(null)

  const searchParams = ref({
    page: 1,
    size: 20,
    total: 0,
    state: [],
    inf_name: [],
    inf_phone: [],
    inf_email: [],
    store_url: [],
    store_name: [],
    created_at: [],
    ...router.history.current.query,
  })

  const searchKeys = ['state', 'inf_name', 'inf_phone', 'inf_email', 'store_url', 'store_name', 'created_at']
  searchKeys.forEach(key => {
    if (searchParams.value[key] == null || searchParams.value[key] === '') {
      searchParams.value[key] = []
    } else if (typeof searchParams.value[key] === 'string') {
      searchParams.value[key] = [searchParams.value[key]]
    }
  })

  // Table Handlers
  const tableColumns = [
    { key: 'idx' },
    { key: 'inf_idx', label: '인플루언서' },
    { key: 'url_path', label: 'URL 열기' },
    { key: 'detail', label: '상세보기' },
    { key: 'name', label: '스토어명' },
    { key: 'item_count', label: '판매상품' },
    { key: 'state', label: '상태' },
    { key: 'created_at', label: '생성일시', formatter: val => `${moment(val).format('YYYY-MM-DD HH:mm:ss')}` },
    // { key: 'actions' },
  ]
  const stores = ref([])
  const perPage = ref(searchParams.value.size)
  const currentPage = ref(searchParams.value.page)
  const totalCount = ref(searchParams.value.total)
  const isBusy = ref(false)
  const isDelay = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refStoreListTable.value ? refStoreListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCount.value,
    }
  })

  const datetimePreprocessing = datetime => datetime
    .map(o => o
      .split(' to ')
      .map(date => moment(new Date(date).setHours(0))
        .utc()
        .format('YYYY-MM-DD HH'))
      .join(' to '))

  const fetchStoreList = () => {
    isBusy.value = true
    store
      .dispatch('app-mystore/fetchStoreList', {
        ...searchParams.value,
        created_at: datetimePreprocessing(searchParams.value.created_at),
      })
      .then(response => {
        const { page_info, data } = response.data
        stores.value = data
        totalCount.value = page_info.total_count
        searchParams.value.total = page_info.total_count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching store list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        isBusy.value = false
        router.replace({
          query: searchParams.value,
        }).catch(() => {})
      })
  }

  const fetchIsDelay = () => {
    store
      .dispatch('app-mystore/fetchIsDelay')
      .then(response => {
        isDelay.value = response.data.data.is_delay
      })
      .catch()
  }

  const setIsDelay = () => {
    store
      .dispatch('app-mystore/setIsDelay', {
        is_delay: isDelay.value,
      })
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '지연 안내 노출 변경에 성공 했습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'primary',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '지연 안내 노출 변경에 실패 했습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        fetchIsDelay()
      })
  }

  const visibleSetIsDelayModal = ref(false)
  const setIsDelayModalMessage = ref()
  const turnOnSetIsDelayModal = changedIsDelay => {
    visibleSetIsDelayModal.value = true
    isDelay.value = changedIsDelay
    if (changedIsDelay) {
      setIsDelayModalMessage.value = '마이스토어 지연 안내 팝업을 띄우시겠습니까?'
    } else {
      setIsDelayModalMessage.value = '마이스토어 지연 안내 팝업을 내리시겠습니까?'
    }
  }
  const turnOffSetIsDelayModal = () => {
    visibleSetIsDelayModal.value = false
    fetchIsDelay()
  }

  watch([currentPage, perPage], () => {
    searchParams.value.page = currentPage.value
    searchParams.value.size = perPage.value
    fetchStoreList()
  })

  return {
    fetchStoreList,
    tableColumns,
    perPage,
    currentPage,
    totalCount,
    stores,
    dataMeta,
    refStoreListTable,
    isBusy,
    searchParams,
    isDelay,
    fetchIsDelay,
    setIsDelay,
    visibleSetIsDelayModal,
    setIsDelayModalMessage,
    turnOnSetIsDelayModal,
    turnOffSetIsDelayModal,
  }
}
