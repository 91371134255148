import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    // 스토어 리스트 받기
    fetchStoreList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/mystore/list/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchIsDelay() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/mystore/is-delay/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    setIsDelay(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/mystore/set-is-delay/`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
