<template>
  <b-card class="p-100">
    <b-card-header>
      <b-row class="w-100 mb-1">
        <b-col class="d-flex">
          <b-form-checkbox
            :checked="isDelay"
            name="check-button"
            switch
            inline
            @change="$emit('turn-on-set-is-delay-modal', $event)"
          >
            지연 안내 노출
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="w-100">
        <b-col>
          <h2 class="pb-50">
            <slot name="header">
              검색 필터
            </slot>
          </h2>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group
            label="카테고리별 검색"
          >
            <b-row>
              <b-col
                cols="12"
                xl="3"
              >
                <v-select
                  v-model="textCategorySelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="textCategoryOptions"
                  class="invoice-filter-select"
                  placeholder="검색 항목 선택"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-col>
              <b-col
                v-if="isNotCheckbox"
                cols="12"
                xl="4"
              >
                <b-input-group>
                  <flat-pickr
                    v-if="isDateInput"
                    v-model="textInput"
                    class="form-control"
                    :config="{ mode: 'range'}"
                  />
                  <b-form-input
                    v-else
                    v-model="textInput"
                    class="d-inline-block"
                    placeholder="검색 텍스트 입력"
                    @keyup.enter="addTag"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="addTag"
                    >
                      Add
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col
                v-else
                cols="12"
                xl="9"
                class="d-flex align-items-center"
              >
                <b-form-checkbox-group
                  v-if="isStateInput"
                  v-model="searchParams.state"
                  :options="stateOptions"
                  name="buttons-1"
                />
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-tags
            v-model="tagParams"
            input-id="tags-basic"
            class="mb-2"
            :tag-validator="() => false"
            invalid-tag-text="이곳에 직접 입력할 수 없습니다"
            placeholder="위에서 입력한 값을 보여줍니다"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          class="d-flex btn-box"
          style="justify-content: space-between;"
        >
          <b-button
            variant="gradient-primary"
            @click="search"
          >
            검색
          </b-button>
          <b-button
            variant="gradient-primary"
            @click="createMystore"
          >
            마이스토어 생성
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTags,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
    BFormCheckbox,
  },
  props: {
    searchParams: {
      type: Object,
      default: null,
    },
    isDelay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stateOptions: [
        { text: 'ACTIVE', value: 'ACTIVE' },
        { text: 'INACTIVE', value: 'INACTIVE' },
      ],
      textCategoryOptions: [
        { label: '스토어URL', value: 'STORE_URL' },
        { label: '스토어명', value: 'STORE_NAME' },
        { label: '인플루언서 이름', value: 'INF_NAME' },
        { label: '인플루언서 휴대번호', value: 'INF_PHONE' },
        // { label: '인플루언서 이메일', value: 'INF_EMAIL' },
        { label: '상태', value: 'STATE' },
        { label: '생성일시', value: 'CREATED_AT' },
      ],
      textCategorySelect: { label: '스토어URL', value: 'STORE_URL' },
      textInput: '',
    }
  },
  computed: {
    isDateInput: {
      get() {
        return this.textCategorySelect?.value === 'CREATED_AT'
      },
    },
    isStateInput: {
      get() {
        return this.textCategorySelect?.value === 'STATE'
      },
    },
    isNotCheckbox: {
      get() {
        return !this.isStateInput
      },
    },
    tagParams: {
      get() {
        const results = []
        const state = this.stateOptions.filter(o => this.searchParams.state.includes(o.value)).map(o => `상태:${o.text}`)
        const infName = this.searchParams.inf_name.map(o => `인플루언서_이름:${o}`)
        const infPhone = this.searchParams.inf_phone.map(o => `인플루언서_휴대번호:${o}`)
        const infEmail = this.searchParams.inf_email.map(o => `인플루언서_이메일:${o}`)
        const storeUrl = this.searchParams.store_url.map(o => `스토어URL:${o}`)
        const storeName = this.searchParams.store_name.map(o => `스토어명:${o}`)
        const createdAt = this.searchParams.created_at.map(o => `생성일시:${o}`)
        return results
          .concat(state)
          .concat(infName)
          .concat(infPhone)
          .concat(infEmail)
          .concat(storeUrl)
          .concat(storeName)
          .concat(createdAt)
      },

      set(items) {
        Object.assign(this.searchParams, {
          state: [],
          inf_name: [],
          inf_phone: [],
          inf_email: [],
          store_url: [],
          store_name: [],
          created_at: [],
        })
        items.forEach(item => {
          const [key, value] = item.split(':')
          if (key === '상태') {
            const state = this.stateOptions.find(o => o.text === value)
            this.searchParams.state.push(state.value)
          } else if (key === '인플루언서_이름') {
            this.searchParams.inf_name.push(value)
          } else if (key === '인플루언서_휴대번호') {
            this.searchParams.inf_phone.push(value)
          } else if (key === '인플루언서_이메일') {
            this.searchParams.inf_email.push(value)
          } else if (key === '스토어URL') {
            this.searchParams.store_url.push(value)
          } else if (key === '스토어명') {
            this.searchParams.store_name.push(value)
          } else if (key === '생성일시') {
            this.searchParams.created_at.push(value)
          }
        })
      },
    },
  },
  mounted() {
    window.addEventListener('keydown', e => {
      if (e.code === 'Enter' && (e.metaKey || e.ctrlKey)) {
        this.search()
      }
    })
  },
  methods: {
    test() {
      console.log(this.searchParams)
    },
    addTag() {
      const key = this.textCategorySelect?.label
      const value = this.textInput
      if (value != null && value !== '') {
        if (key === '인플루언서 이름') {
          this.searchParams.inf_name.push(value)
        } else if (key === '인플루언서 휴대번호') {
          this.searchParams.inf_phone.push(value)
        } else if (key === '인플루언서 이메일') {
          this.searchParams.inf_email.push(value)
        } else if (key === '스토어URL') {
          this.searchParams.store_url.push(value)
        } else if (key === '스토어명') {
          this.searchParams.store_name.push(value)
        } else if (key === '생성일시') {
          this.searchParams.created_at.push(value)
        }
      }
      this.textInput = null
    },
    search() {
      this.$emit('search', this.searchParams)
    },
    createMystore() {
      this.$router.push({ name: 'commerce-mystore-create' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
