<template>

  <div>
    <!-- Table Container Card -->
    <mystore-list-filters
      :search-params="searchParams"
      :is-delay="isDelay"
      @search="fetchStoreList"
      @turn-on-set-is-delay-modal="turnOnSetIsDelayModal"
    />

    <b-card
      no-body
      class="mb-0"
    >

      <b-table
        ref="refStoreListTable"
        class="position-relative"
        primary-key="idx"
        show-empty
        empty-text="No matching records found"
        :items="stores"
        :fields="tableColumns"
        :busy="isBusy"
        small
        responsive
      >

        <template #table-busy>
          <div class="text-center">
            <b-spinner
              variant="primary"
              label="API Loading..."
            />
            <div>
              <strong>Loading...</strong>
            </div>
          </div>
        </template>

        <template #cell(inf_idx)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'member-inf-detail', params: { idx: data.item.inf_idx } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ resolveInfName(data.item.inf) }}
            </b-link>
            <small class="text-muted">{{ resolveInfPhone(data.item.inf) }}</small>
          </b-media>
        </template>

        <template #cell(url_path)="data">
          <a
            :href="`https://store.kr.tagby.io/id/${data.item.url_path}`"
            target="_blank"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.url_path }}
          </a>
        </template>

        <template #cell(detail)="data">
          <b-link
            :to="{ name: 'commerce-mystore-view', params: { idx: data.item.idx } }"
            class="font-weight-bold d-block text-nowrap"
          >
            상세보기
          </b-link>
        </template>

        <template #cell(created_at)="data">
          <div style="max-width: 90px;">
            {{ data.value }}
          </div>
        </template>

        <!-- Column: Actions -->
        <!--        <template #cell(actions)="data">-->
        <!--          <b-dropdown-->
        <!--              variant="link"-->
        <!--              no-caret-->
        <!--              :right="$store.state.appConfig.isRTL"-->
        <!--          >-->

        <!--            <template #button-content>-->
        <!--              <feather-icon-->
        <!--                  icon="MoreVerticalIcon"-->
        <!--                  size="16"-->
        <!--                  class="align-middle text-body"-->
        <!--              />-->
        <!--            </template>-->
        <!--            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.idx } }">-->
        <!--              <feather-icon icon="FileTextIcon" />-->
        <!--              <span class="align-middle ml-50">Details</span>-->
        <!--            </b-dropdown-item>-->

        <!--            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.idx } }">-->
        <!--              <feather-icon icon="EditIcon" />-->
        <!--              <span class="align-middle ml-50">Edit</span>-->
        <!--            </b-dropdown-item>-->

        <!--            <b-dropdown-item>-->
        <!--              <feather-icon icon="TrashIcon" />-->
        <!--              <span class="align-middle ml-50">Delete</span>-->
        <!--            </b-dropdown-item>-->
        <!--          </b-dropdown>-->
        <!--        </template>-->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      :visible="visibleSetIsDelayModal"
      ok-title="확인"
      ok-variant="primary"
      cancel-title="취소"
      cancel-variant="outline-secondary"
      modal-class="modal-primary"
      centered
      title="지연 안내 팝업 ON/OFF"
      @ok="setIsDelay"
      @hidden="turnOffSetIsDelayModal"
    >
      {{ setIsDelayModalMessage }}
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable,
  BSpinner, BPagination, BMedia, BLink,
  BModal,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import commerceStoreModule from './commerceStoreModule'
import mystoreViewModel from '@/views/commerce/MystoreViewModel'
import MystoreListFilters from './MystoreListFilters.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BLink,
    BSpinner,
    BPagination,
    BModal,
    MystoreListFilters,
  },
  methods: {
    resolveInfName(inf) {
      return inf?.name
    },
    resolveInfPhone(inf) {
      return inf?.phone
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-mystore'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, commerceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      fetchStoreList,
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      stores,
      dataMeta,
      refStoreListTable,
      isBusy,
      searchParams,
      isDelay,
      fetchIsDelay,
      setIsDelay,
      visibleSetIsDelayModal,
      setIsDelayModalMessage,
      turnOnSetIsDelayModal,
      turnOffSetIsDelayModal,
    } = mystoreViewModel()

    fetchStoreList(1, 20)
    fetchIsDelay()

    return {
      fetchStoreList,
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      stores,
      dataMeta,
      isBusy,
      searchParams,
      avatarText,
      refStoreListTable,
      isDelay,
      fetchIsDelay,
      setIsDelay,
      visibleSetIsDelayModal,
      setIsDelayModalMessage,
      turnOnSetIsDelayModal,
      turnOffSetIsDelayModal,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
